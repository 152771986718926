import React, {useEffect, useState} from 'react';
import {Button, Image, Space, Table, Select, Form, DatePicker} from "antd";
import { Option } from "antd/es/mentions";
import {useFetching} from "../../hooks/useFetching";
import UserService from "../../API/UserService";
import {Link} from "react-router-dom";
import ChangePasswordModal from "../../components/users/ChangePasswordModal";
import Search from "antd/lib/input/Search";
import locale from 'antd/lib/date-picker/locale/ru_RU';

const Users = () => {

    const [users, setUsers] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [userStatus, setUserStatus] = useState('');
    const [registrationDateFrom, setDateFrom] = useState(null);
    const [registrationDateTo, setDateTo] = useState(null);
    const onDateFromChange = (date, dateString) => setDateFrom(dateString);
    const onDateToChange = (date, dateString) => setDateTo(dateString);
    const userStatusMap = {
        'WAITING_FOR_CONFIRMATION': 'Ожидает подтверждения',
        'ACTIVE': 'Активный'
    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Аватар',
            key: 'imageUrl',
            render: (text, record) => (
                <Space size="middle">
                    <Image
                        width={75}
                        src={record.imageUrl}
                    />
                </Space>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Номер телефона',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Дата регистрации',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Статус пользователя',
            key: 'status',
            render: (text, record) => {
                return <Space size="middle">
                    {userStatusMap[record.status]}
                </Space>
            }
        },
        {
            title: 'Доступные бонусы',
            dataIndex: 'availableBonuses',
            key: 'availableBonuses'
        },
        {
            title: 'Фамилия',
            dataIndex: 'lastName',
            key: 'lastName'
        },
        {
            title: 'Имя',
            dataIndex: 'firstName',
            key: 'firstName'
        },
        {
            title: 'Дейсвтия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Link type="primary" to={`/users/${record.id}`}>Просмотр</Link>
                    <Button onClick={() => showModal(record)}>Изменить пароль</Button>
                </Space>
            )
        }
    ]

    const [fetchUsers, isUsersLoading] = useFetching(
        async (pagination = {pageSize: 20, current: 1}) => {
            const payload = {
                page: pagination.current - 1,
                size: pagination.pageSize,
                searchTerm,
                registrationDateFrom,
                registrationDateTo,
                status: userStatus,
            }
            const {data} = await UserService.getAll(payload);
            setUsers(data.content);
            setPagination({
                current: data.pageable.pageNumber + 1,
                pageSize: data.pageable.pageSize,
                total: data.totalElements
            });
        }
    );

    const handleTableChange = async (pagination) => {
        await fetchUsers(pagination);
    }

    const [visible, setVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});

    const showModal = (record) => {
        setSelectedUser(record);
        setVisible(true);
    };

    useEffect(() => {
        fetchUsers(pagination);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearch = value => setSearchTerm(value);

    useEffect(() => {
        fetchUsers();
    }, [searchTerm, userStatus, registrationDateFrom, registrationDateTo])

    return (
        <>
            <Search placeholder="Поиск по ФИО, номеру тел. или email" onSearch={onSearch} />
            <Form.Item label="Выберите статус пользователя" style={{ marginBottom: 20, marginTop: 20}}>
                <Select
                    placeholder="Выберите статус пользователя"
                    value={userStatus}
                    onChange={(val) => setUserStatus(val)}
                    style={{width: 200}}
                >
                    <Option value="ACTIVE">Активный</Option>
                    <Option value="WAITING_FOR_CONFIRMATION">Ожидает подтверждения</Option>
                </Select>
                <Button onClick={() => setUserStatus(null)} style={{marginLeft: 20}}>
                    Сбросить
                </Button>
            </Form.Item>
            <Form.Item
                label="Начало даты регистрации:" style={{ marginBottom: 20, marginTop: 20}}>
                <DatePicker onChange={onDateFromChange} placeholder="От" style={{ width: 200, marginRight: 20 }} locale={locale} />
                </Form.Item>
            <Form.Item
                label="Конец даты регистрации:" style={{ marginBottom: 20, marginTop: 20}}>
                <DatePicker onChange={onDateToChange} placeholder="До" style={{ width: 200 }} locale={locale}/>
                </Form.Item>
            <Table columns={columns}
                   dataSource={users}
                   rowKey='userId'
                   loading={isUsersLoading}
                   pagination={pagination}
                   onChange={handleTableChange}
            />
            <ChangePasswordModal
                user={selectedUser}
                visible={visible}
                setVisible={setVisible}
            />
        </>
    );
};

export default Users;