import axios from "axios";

export default class OrderService {
    static async getAll(payload) {
        const { current, pageSize } = payload;
        const params = { ...payload, page: Number.isNaN(Number(current)) ? 0 : Number(current) - 1, size: pageSize, sort: 'createdAt,desc' };
        Object.keys(params).forEach(key => (params[key] === undefined || params[key] === null || params[key] === '') && delete params[key]);
        return await axios.get('/order-service/admin/orders', { params });
    }

    static async exportCsv(payload) {
        const params = { ...payload };
        return await axios.get('/order-service/admin/orders/exportCsv', { params });
    }

    static async getById(id) {
        return await axios.get(`/order-service/admin/orders/${id}`);
    }

    static async cancel(id) {
        return await axios.post(`/order-service/admin/orders/${id}/cancel`);
    }

    static async count() {
        return await axios.get(`/order-service/admin/orders/counts`);
    }

    static async grouped() {
        return await axios.get(`/order-service/admin/orders/grouped`);
    }
}