import React from 'react';
import {Space, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import {useFetching} from "../../hooks/useFetching";
import OrderService from "../../API/OrderService";
import Modal from "antd/es/modal/Modal";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined
} from "@ant-design/icons";

const OrderList = ({orders, isLoading, pagination, handleTableChange}) => {
    const columns = [
        {
            title: 'Номер заказа',
            dataIndex: 'orderNum',
            key: 'orderNum'
        },
        {
            title: 'Сумма',
            dataIndex: 'totalAmount',
            key: 'totalAmount'
        },
        {
            title: 'Количество',
            key: 'itemsAmount',
            render: (text, record) => {
                return record.items.length
            }
        },
        {
            title: 'Статус',
            key: 'status',
            render: (text, record) => {
                if (record.status === 'CREATED') {
                    return <Tag icon={<SyncOutlined spin />} color="processing">
                        В обработке
                    </Tag>
                } else if (record.status === 'PAID') {
                    return <Tag icon={<CheckCircleOutlined spin />} color="success">
                        Оплачен
                    </Tag>
                } else if (record.status === 'FAILED') {
                    return <Tag icon={<CloseCircleOutlined/>} color="error">
                        Ошибка платежа
                    </Tag>
                } else if (record.status === 'CANCELLED') {
                    return <Tag icon={<MinusCircleOutlined/>} color="default">
                        Отменен
                    </Tag>
                }
            }
        },
        {
            title: 'Метод оплаты',
            key: 'serviceType',
            render: (text, record) => (
                record.serviceType
            )
        },
        {
            title: 'Источник заказа',
            key: 'platformType',
            render: (text, record) => (
                record.platformType
            )
        },
        // {
        //     title: 'SkiPass',
        //     key: 'subtitleRu',
        //     render: (text, record) => (
        //         record.orders.map(ticket => ticket.skiPass).join(',')
        //     )
        // },
        // {
        //     title: 'Qr data',
        //     key: 'subtitleRu',
        //     render: (text, record) => (
        //         record.orders.map(ticket => ticket.qrData).join(',')
        //     )
        // },
        // {
        //     title: 'Кол-во билетов',
        //     key: 'ticketCount',
        //     render: (text, record) => (
        //         record.orders.length
        //     )
        // },
        // {
        //     title: 'Сумма к оплате',
        //     dataIndex: 'amountToPay',
        //     key: 'amountToPay'
        // },
        // {
        //     title: 'Использовано бонусов',
        //     dataIndex: 'bonusAmount',
        //     key: 'bonusAmount'
        // },
        // {
        //     title: 'Итого',
        //     dataIndex: 'totalAmount',
        //     key: 'totalAmount'
        // },
        // {
        //     title: 'Кэшбек',
        //     dataIndex: 'cashback',
        //     key: 'cashback'
        // },
        // {
        //     title: 'Скидка',
        //     dataIndex: 'discount',
        //     key: 'discount'
        // },
        // {
        //     title: 'Mastercard',
        //     key: 'isMastercard',
        //     render: (text, record) => {
        //         return record.isMastercard ? 'Да' : 'Нет'
        //     }
        // },
        // {
        //     title: 'Страховка',
        //     key: 'insurance',
        //     render: (text, record) => (
        //         record.insurance ?
        //             `id: ${record.insurance.id}, ${record.insurance.policyNumber}, ${record.insurance.policyStatus}`
        //             : '-'
        //     )
        // },
        {
            title: 'Пользователь',
            key: 'userId',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/users/${record.userId}`}>{record.userName}</Link>
                </Space>
            )
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedAt',
            key: 'updatedDate'
        },
        {
            title: 'Дейсвтия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/orders/${record.id}`}>Детали заказа</Link>
                </Space>
            )
        }
    ];

    const [cancelOrder, isCancelling] = useFetching(async (id) => {
        await OrderService.cancel(id);
        await handleTableChange(pagination);
    });

    const onCancelOrder = async (order) => {
        Modal.confirm({
            title: 'Отменить заказ? Только для не оплаченных заказов.',
            icon: <ExclamationCircleOutlined/>,
            content: `Заказ #${order.id} будет отменен.`,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Отменить',
            onOk() {
                return cancelOrder(order.id);
            },
            onCancel() {
            },
        });
    }

    return (
        <div>
            <Table columns={columns}
                   dataSource={orders}
                   rowKey='id'
                   loading={isLoading || isCancelling}
                   pagination={pagination}
                   onChange={handleTableChange}
            />
        </div>
    );
};

export default OrderList;