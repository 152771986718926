import React, { useEffect, useState } from 'react';
import { Table, Space } from "antd";
import { useFetching } from "../../hooks/useFetching";
import ErrorLogsService from "../../API/ErrorLogsService";

const ErrorLogs = () => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Версия приложения',
            dataIndex: 'appVersion',
            key: 'appVersion'
        },
        {
            title: 'Логи ошибок',
            dataIndex: 'crashLogs',
            key: 'crashLogs',
            render: (text, record) => (
                <Space size="middle">
                    {record.crashLogs.length > 50 ? record.crashLogs.slice(0, 50) + '...' : record.crashLogs}
                </Space>
            )
        },
        {
            title: 'Полное имя',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Номер телефона',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Версия ОС',
            dataIndex: 'osVersion',
            key: 'osVersion',
        },
        {
            title: 'Наименование телефона',
            dataIndex: 'phoneName',
            key: 'phoneName',
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate',
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate',
        }
    ];

    const [errorLogs, setErrorLogs] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });
    
    const handleTableChange = async (pagination) => {
        await fetchErrorLogs(pagination);
    };

    const [fetchErrorLogs, isLoading] = useFetching(async (pagination = {pageSize: 20, current: 1}) => {
        try {
            const payload = {
                current: pagination.current - 1,
                pageSize: pagination.pageSize,
            }
            const {data} = await ErrorLogsService.getAll(payload);
            setErrorLogs(data?.content);
            setPagination({
                current: data.pageable.pageNumber + 1,
                pageSize: data.pageable.pageSize,
                total: data.totalElements
            });
        } catch (error) {
            console.error(error);
        }
    });

    useEffect(() => {
        fetchErrorLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Table
                columns={columns}
                dataSource={errorLogs}
                rowKey='id'
                expandable={{
                    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.crashLogs}</p>,
                }}
                loading={isLoading}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </>
    );
};

export default ErrorLogs;