import React, { useEffect, useState } from 'react';
import { Table, Space, Select, Form, Button } from "antd";
import { useFetching } from "../../hooks/useFetching";
import { Link } from "react-router-dom";
import { Option } from "antd/es/mentions";
import { DatePicker } from 'antd';
import VerigramService from "../../API/VerigramService";
import Search from "antd/lib/input/Search";
import locale from 'antd/lib/date-picker/locale/ru_RU';

const List = () => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/users/${record.user.id}`}>{record.user.id}</Link>
                </Space>
            )
        },
        {
            title: 'Имя',
            dataIndex: 'firstName',
            key: 'firstName'
        },
        {
            title: 'Фамилия',
            dataIndex: 'lastName',
            key: 'lastName'
        },
        {
            title: 'Отчество',
            dataIndex: 'middleName',
            key: 'middleName'
        },
        {
            title: 'ИИН',
            dataIndex: 'iin',
            key: 'iin'
        },
        {
            title: 'Тип документа',
            dataIndex: 'docType',
            key: 'docType',
            render: (text, record) => (
                <Space size="middle">
                    {docTypeMap[record.docType]}
                </Space>
            )
        },
        {
            title: 'Дата верификации',
            dataIndex: 'updatedDate',
            key: 'updatedDate',
        },
        {
            title: 'Источник регистрации',
            dataIndex: 'platformType',
            render: (text, record) => (
                <Space size="middle">
                    {platformTypeMap[record.platformType]}
                </Space>
            ),
        },
        {
            title: 'Пол',
            dataIndex: 'gender',
            render: (text, record) => (
                <Space size="middle">
                    {genderMap[record.gender]}
                </Space>
            ),
        },
        {
            title: 'Действия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/verigram/${record.id}`} onClick={saveDataToLocalStorage(record)}>Подробнее</Link>
                </Space>
            ),
        }
    ];
    const platformTypeMap = {
        'WEB': 'Веб-браузер',
        'ANDROID': 'Android устройства',
        'IOS': 'ios Устройства'
    };
    const genderMap = {
        'M': 'Мужской',
        'F': 'Женский'
    }
    const [verigramList, setVerigramList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [platformType, setPlatformType] = useState('');
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const onDateFromChange = (date, dateString) => setDateFrom(dateString);
    const onDateToChange = (date, dateString) => setDateTo(dateString);

    const docTypeMap = {
        1: 'Удостоверение РК',
        33: 'Вид на жительство иностранца РК',
        43: 'Паспорта всех стран в формате TD3',
        44: 'Внутренний российский паспорт'
    };

    const saveDataToLocalStorage = (record) => {
        localStorage.setItem('verigram-single-data', JSON.stringify(record));
    }

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });
    const [fetchVerigramList, isVerigramListLoading] = useFetching(async (page, size) => {
        const {data} = await VerigramService.getList(
            {page, size, searchTerm, platformType, verificationDateFrom: dateFrom, verificationDateTo: dateTo}
        );
        setVerigramList(data?.content);
        const pageable = data?.pageable;
        setPagination({
            current: pageable?.pageNumber + 1,
            pageSize: pagination.pageSize,
            total: data.totalElements,
        });
    });

    const handleTableChange = async (pagination) => {
        console.log('hugs');
        await fetchVerigramList(pagination.current, pagination.pageSize, null);
    }

    useEffect(() => {
        fetchVerigramList(pagination.current, pagination.pageSize);
    }, [searchTerm, platformType, dateFrom, dateTo]);

    const onSearch = value => setSearchTerm(value);

    return (
        <>
            <Search placeholder="Поиск по имени, фамилии, отчеству, ИИН" onSearch={onSearch} />
            <Form.Item label="Выберите источник регистрации" style={{ marginBottom: 20, marginTop: 20}}>
                <Select
                    placeholder="Выберите источник регистрации"
                    value={platformType}
                    onChange={(val) => setPlatformType(val)}
                    style={{width: 200}}
                >
                    <Option value="WEB">Web устройства</Option>
                    <Option value="IOS">iOS устройства</Option>
                    <Option value="ANDROID">Android устройства</Option>
                </Select>
                <Button onClick={() => setPlatformType(null)} style={{marginLeft: 20}}>
                    Сбросить
                </Button>
            </Form.Item>
            <Form.Item
                label="Выберите даты верификации" style={{ marginBottom: 20, marginTop: 20}}>
                <DatePicker onChange={onDateFromChange} placeholder="Дата начала" style={{ width: 200, marginRight: 20 }} locale={locale}/>
                <DatePicker onChange={onDateToChange} placeholder="Дата конца" style={{ width: 200 }} locale={locale}/>
            </Form.Item>
            <Table 
                className="verigram-table"
                columns={columns}
                dataSource={verigramList}
                rowKey='id'
                loading={isVerigramListLoading}
                pagination={pagination}
                onChange={handleTableChange}/>
        </>
    );
};

export default List;