import axios from "axios";

export default class VerigramService {
    static async getList(payload) {
        const { page, size, searchTerm, platformType, verificationDateFrom, verificationDateTo } = payload;
        const params = {
            page: page - 1, pageSize: size,
            searchTerm,
            platformType,
            verificationDateFrom,
            verificationDateTo,
            sort: 'updatedDate,desc'
        };
        Object.keys(params).forEach(key => (params[key] === undefined || params[key] === null || params[key] === '') && delete params[key]);
        return await axios.get('/user-profile-service/admin/verigram', { params });
    }

    static async getById(id) {
        return await axios.get(`/user-profile-service/admin/verigram/${id}`);
    }
}