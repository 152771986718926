import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useFetching} from "../../hooks/useFetching";
import VerigramService from "../../API/VerigramService";
import {Descriptions, Space, PageHeader} from "antd";
import { Link } from "react-router-dom";

const VerigramIdPage = () => {
    const params = useParams();
    const router = useHistory();

    const [verigramData, setVerigramData] = useState({});

    const [loadVerigramData] = useFetching(async (id) => {
        const {data} = await VerigramService.getById(id);
        setVerigramData(data);
    });

    const genderMap = {
        'M': 'Мужской',
        'F': 'Женский'
    }

    const docTypeMap = {
        1: 'Удостоверение РК',
        33: 'Вид на жительство иностранца РК',
        43: 'Паспорта всех стран в формате TD3',
        44: 'Внутренний российский паспорт'
    };

    const platformTypeMap = {
        'WEB': 'Веб-браузер',
        'ANDROID': 'Android устройства',
        'IOS': 'ios Устройства'
    }

    useEffect(() => {
        loadVerigramData(params.id);
    }, []);

    return (
        <div style={{background: '#fff'}}>
            <PageHeader
                className="site-page-header"
                onBack={() => router.goBack()}
                title="Информация о верифицированном пользователе"
            />
            <div style={{padding: 32}}>
                <Descriptions
                    bordered
                >
                    <Descriptions.Item label="Фото">
                        <img src={'data:image/png;base64, ' + verigramData.imageUrl} alt='verigram' style={{width: '100px'}}/>
                    </Descriptions.Item>
                    <Descriptions.Item label="Имя">{verigramData.firstName}</Descriptions.Item>
                    <Descriptions.Item label="Фамилия">{verigramData.lastName}</Descriptions.Item>
                    <Descriptions.Item label="Отчество">{verigramData.middleName}</Descriptions.Item>
                    <Descriptions.Item label="Email">{verigramData.email}</Descriptions.Item>
                    <Descriptions.Item label="Телефон">{verigramData.phone}</Descriptions.Item>
                    <Descriptions.Item label="Дата рождения">
                            {
                                verigramData.birthDate ? verigramData.birthDate.replace(/(\d{2})(\d{2})(\d{4})/, '$1.$2.$3') : 'Не указано'
                            }
                    </Descriptions.Item>
                    <Descriptions.Item label="ИИН">{verigramData.iin}</Descriptions.Item>
                    <Descriptions.Item label="Пол">{genderMap[verigramData.gender]}</Descriptions.Item>
                    <Descriptions.Item label="Номер удостоверения">{verigramData.idNumber}</Descriptions.Item>
                    <Descriptions.Item label="Место рождения">{verigramData.birthPlace}</Descriptions.Item>
                    <Descriptions.Item label="Национальность">{verigramData.nationality}</Descriptions.Item>
                    <Descriptions.Item label="Этнос">{verigramData.ethnicity}</Descriptions.Item>
                    <Descriptions.Item label="Орган выдачи">{verigramData.issuer}</Descriptions.Item>
                    <Descriptions.Item label="Дата выдачи">{verigramData.issueDate?.replace(/(\d{2})(\d{2})(\d{4})/, '$1.$2.$3')}</Descriptions.Item>
                    <Descriptions.Item label="Срок удостоверения">{verigramData.dueDate?.replace(/(\d{2})(\d{2})(\d{4})/, '$1.$2.$3')}</Descriptions.Item>
                    <Descriptions.Item label="State code">{verigramData.stateCode}</Descriptions.Item>
                    <Descriptions.Item label="mrz Country">{verigramData.mrzCountry}</Descriptions.Item>
                    <Descriptions.Item label="mrz First name">{verigramData.mrzFirstName}</Descriptions.Item>
                    <Descriptions.Item label="mrz Last name">{verigramData.mrzLastName}</Descriptions.Item>
                    <Descriptions.Item label="Тип документа">{docTypeMap[verigramData.docType]}</Descriptions.Item>
                    <Descriptions.Item label="Является ли резидентом">{verigramData.resident ? 'Резидент' : 'Не резидент'}</Descriptions.Item>
                    <Descriptions.Item label="Платформа верификации">{platformTypeMap[verigramData.platformType]}</Descriptions.Item>
                    <Descriptions.Item label="Дата верификации">{verigramData.updatedDate}</Descriptions.Item>
                </Descriptions>
                <Space size="middle" style={{marginTop: 20}}>
                    <Link to={`/users/${verigramData?.user?.id}`}>Страница пользователя</Link>
                </Space>
            </div>
        </div>
    );
};
export default VerigramIdPage;