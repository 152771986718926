import axios from "axios";

export default class PaymentService {
    static async getAll(page) {
        const params = {
            page
        };
        return await axios.get('/payment-service/admin/payments/events', { params });
    }

    static async cancelPayment(invoiceId) {
        return await axios.post(`/payment-service/admin/payments/cancel?invoiceId=${invoiceId}`);
    }

    static async cancelTiptopPayment(invoiceId) {
        return await axios.post(`/payment-service/admin/payments/tiptop/cancel?invoiceId=${invoiceId}`);
    }
}