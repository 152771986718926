import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import {useFetching} from "../../hooks/useFetching";
import OrderService from "../../API/OrderService";
import PaymentService from "../../API/PaymentService";
import {notification, Button, Descriptions, Image, PageHeader, Space, Table, Tag} from "antd";
import Modal from "antd/es/modal/Modal";
import UserService from "../../API/UserService";
import {CheckCircleOutlined, MinusCircleOutlined, SyncOutlined} from "@ant-design/icons";
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import QRCode from 'qrcode';

const OrderIdPage = () => {
    const params = useParams();
    const router = useHistory();
    const [order, setOrder] = useState({});
    const [user, setUser] = useState({});
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [isLoadingCancel, setIsLoadingCancel] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [qrModalVisible, setQrModalVisible] = useState(false);
    const [qrData, setQrData] = useState('');
    const [skiPass, setSkiPass] = useState('');
    const [qrImage, setQrImage] = useState('');
    const handleQRClick = (record) => {
        try {

            setSkiPass(record.skiPass);
            setQrData(record.qrData);
            setQrModalVisible(true);
            QRCode.toDataURL(record.qrData, function (err, url) {
                if (err) {
                    console.error(err)
                } else {
                    setQrImage(url);
                }
            });
            setTimeout(() => {
                QRCode.toCanvas(
                    document.getElementById('qrCodeCanvas'), 
                    record.qrData, 
                    function (error) {
                        if (error) console.error(error);
                    });
            }, 100);
        } catch(e) {
            console.log(e);
        }
    };
    const handleQRModalCancel = () => {
        setQrModalVisible(false);
    };
    const downloadQR = () => {
        try {
            const canvas = document.getElementById('qrCodeCanvas');
            const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            let downloadLink = document.createElement('a');
            downloadLink.href = pngUrl;
            downloadLink.download = 'QR_DATA: ' + qrData + '.png';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (e) {
            console.log(e);
        }
    };

    const cancelPayment = async () => {
        try {
            setIsLoadingCancel(true);
            if (order.serviceType === 'TIP_TOP') {
                await PaymentService.cancelTiptopPayment(order.orderNum);
            } else if (order.serviceType === 'EPAYMENTS'){
                await PaymentService.cancelPayment(order.orderNum);
            }
            fetchOrder(params.id);
            api.open({
                message: `Успешно удалено`,
                description: `Удаление прошло успешно`,
                duration: 4.5,
            });
            setCancelModalVisible(false);
        } catch (e) {
            console.log(e);
            api.open({
                message: `Ошибка при удалении`,
                description: `Ошибка при удалении транзакции`,
                duration: 4.5,
            });
        } finally {
            setIsLoadingCancel(false);
        }
    };

    const showCancelModal = () => {
        setCancelModalVisible(true);
    };

    const [isPossibleToCancel, setIsPossibleToCancel] = useState(true);

    const [fetchOrder, isLoading] = useFetching(async (id) => {
        const {data} = await OrderService.getById(id);
        const userResponse = await UserService.getByUserId(data.userId);
        if (data.status === 'CANCELLED' || data.serviceType === 'KASPI') {
            setIsPossibleToCancel(false);
        }
        setOrder(data);
        setUser(userResponse.data);
    });

    useEffect(() => {
        fetchOrder(params.id);
    }, []);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'id axess тарифа',
            dataIndex: 'axesMetaTariffId',
            key: 'axesMetaTariffId'
        },
        {
            title: 'id axess билета',
            dataIndex: 'axessTicketId',
            key: 'axessTicketId'
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Тип сущности',
            dataIndex: 'entityType',
            key: 'entityType'
        },
        {
            title: 'Наименование услуги',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'ID сущности',
            dataIndex: 'entityId',
            key: 'entityId'
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: 'SkiPass',
            dataIndex: 'skiPass',
            key: 'skiPass'
        },
        {   
            title: 'Нужен ли PickupBox',
            dataIndex: 'isPickupBox',
            key: 'isPickupBox'
        },
        {   
            title: 'QR данные',
            dataIndex: 'qrData',
            key: 'qrData'
        },
        {
            title: 'QR изображение',
            key: 'qrImage',
            dataIndex: 'qrImage',
        },
        {
            title: 'Промокод',
            dataIndex: 'promocode',
            key: 'promocode'
        },
        {
            title: 'Цена страховки',
            dataIndex: 'insurancePrice',
            key: 'insurancePrice'
        },
        {
            title: 'Статус',
            key: 'status',
            render: (text, record) => {
                if (record.status === 'CREATED') {
                    return <Tag icon={<SyncOutlined spin/>} color="processing">
                        В обработке
                    </Tag>
                } else if (record.status === 'RESERVED') {
                    return <Tag icon={<SyncOutlined spin/>} color="success">
                        В резерве
                    </Tag>
                } else if (record.status === 'COMPLETED') {
                    return <Tag icon={<CheckCircleOutlined/>} color="success">
                        Завершен
                    </Tag>
                } else if (record.status === 'CANCELLED') {
                    return <Tag icon={<MinusCircleOutlined/>} color="default">
                        Отменен
                    </Tag>
                }
            }
        },
        {
            title: 'Действия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => handleQRClick(record)} disabled={!record?.qrData}>QR</Button>
                </Space>
            )
        }
    ];

    // Function to generate and download QR images as a ZIP
    const generateQRArchive = async () => {
        if (!order?.items || !order.items.length) {
            return;
        }

        const zip = new JSZip();
        const qrFolder = zip.folder('qr-images');

        try {
            await Promise.all(
              order.items.map(async (item, index) => {
                  if (item.qrData) {
                      // Generate QR Code as a Data URL
                      const qrCodeDataUrl = await QRCode.toDataURL(item.qrData);

                      // Extract the Base64 Content
                      const base64Content = qrCodeDataUrl.replace(/^data:image\/png;base64,/, '');

                      // Add the file to the ZIP folder
                      qrFolder.file(`QR_Code_${index + 1}_${item.date}.png`, base64Content, {base64: true});
                  }
              })
            );

            // Generate the ZIP file and trigger download
            const zipContent = await zip.generateAsync({type: 'blob'});
            saveAs(zipContent, `order-${order.orderNum}.zip`);
        } catch (error) {
            console.error('Error generating QR Codes:', error);
        }
    };

    return (
        <div style={{background: '#fff', display: 'flex', flexDirection: 'column'}}>
            {contextHolder}
            <PageHeader
                className="site-page-header"
                onBack={() => router.goBack()}
                title={`Информация о заказе #${order.orderNum}`}
            />
            <div style={{padding: 32}}>
                <Descriptions
                    bordered
                >
                    <Descriptions.Item label="Фото"><Image
                        width={150}
                        src={user.imageUrl}
                    /></Descriptions.Item>
                    <Descriptions.Item label="ФИО">
                        <Link to={`/users/${user.id}`}>{`${user.firstName} ${user.lastName}`}</Link>
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
                    <Descriptions.Item label="Телефон">{user.phone}</Descriptions.Item>
                    <Descriptions.Item label="Статус">{order.status}</Descriptions.Item>
                    <Descriptions.Item label="Сумма заказа">{order.totalAmount}</Descriptions.Item>
                    <Descriptions.Item label="Сумма к оплате">{order.amountToPay}</Descriptions.Item>
                    <Descriptions.Item
                        label="Использовано бонусов">{order.bonusAmount ? order.bonusAmount : 0}</Descriptions.Item>
                    <Descriptions.Item label="Платформа оплаты">{order.platformType}</Descriptions.Item>
                    <Descriptions.Item label="Сервис оплаты">{order.serviceType}</Descriptions.Item>
                </Descriptions>
            </div>

            <Button
              type="primary"
              style={{marginLeft: '20px', marginTop: '20px', maxWidth: '200px', width: '100%'}}
              onClick={generateQRArchive}
              disabled={!order?.items?.length}
            >
                Скачать QR Архив
            </Button>
            <Table columns={columns}
                   dataSource={order.items}
                   rowKey='id'
                   loading={isLoading}
                   pagination={false}
                   style={{width: '100%', overflow: 'scroll'}}
            />
            <Button type="primary" danger style={{marginLeft: 'auto', marginTop: '20px'}} onClick={() => showCancelModal()} disabled={isPossibleToCancel === false}>Отменить транзакцию</Button>
            <Modal
                title='QR код'
                visible={qrModalVisible}
                onCancel={() => handleQRModalCancel()}
                footer={[
                    <Button key="back" onClick={() => handleQRModalCancel()}>
                        Закрыть
                    </Button>,
                    <Button type="primary" key="download" onClick={() => downloadQR()} >
                        Скачать
                    </Button>
                ]}>
                <div style={
                    {textAlign: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center'}
                }>
                    <canvas id="qrCodeCanvas" style={{display: 'none'}}/>
                    <Image
                        width={200}
                        src={qrImage}
                    />
                    <span>
                        QRData: {qrData}
                    </span>
                    <span>
                        SkiPass: {skiPass}
                    </span>
                </div>
            </Modal>
            <Modal
                title='Отмена транзакции'
                visible={cancelModalVisible}
                onCancel={() => setCancelModalVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setCancelModalVisible(false)}>
                        Отмена
                    </Button>,
                    <Button type="primary" key="submit" onClick={() => cancelPayment()} loading={isLoadingCancel}>
                        Подтвердить
                    </Button>
                ]}>
                <p>Вы уверены, что хотите отменить транзакцию?</p>
            </Modal>
        </div>
    );
};

export default OrderIdPage;