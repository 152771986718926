import React, { useEffect, useState } from 'react';
import { useFetching } from "../../hooks/useFetching";
import { Divider, DatePicker, Input, Button, Form, Select } from "antd";
import { Option } from "antd/es/mentions";
import OrderService from "../../API/OrderService";
import OrderList from "../../components/orders/OrderList";
import locale from 'antd/lib/date-picker/locale/ru_RU';

const Orders = () => {
    const paginationInitState = {
        current: 1,
        pageSize: 20,
    };
    const [orders, setOrders] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [amountMin, setAmountMin] = useState(null);
    const [amountMax, setAmountMax] = useState(null);
    const [createdAtFrom, setDateFrom] = useState(null);
    const [createdAtTo, setDateTo] = useState(null);
    const onDateFromChange = (date, dateString) => setDateFrom(dateString);
    const onDateToChange = (date, dateString) => setDateTo(dateString);

    const [orderStatus, setOrderStatus] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const onSearch = () => {
        setPagination(paginationInitState);
        fetchOrders({
            current: paginationInitState.current,
            pageSize: paginationInitState.pageSize, 
            firstCall: true,
            searchTerm: searchTerm,
            amountMin: amountMin,
            amountMax: amountMax,
            createdAtFrom: createdAtFrom,
            createdAtTo: createdAtTo,
            status: orderStatus,
            serviceType: paymentMethod
        });
    };
    const resetFilters = () => {
        setSearchTerm('');
        setAmountMin(null);
        setAmountMax(null);
        setPagination(paginationInitState);
        fetchOrders({
            current: paginationInitState.current,
            pageSize: paginationInitState.pageSize,
            firstCall: true
        });
    };

    const [fetchOrders, isLoading] = useFetching(async (payload) => {
        const {
            firstCall
        } = payload;

        const {data} = await OrderService.getAll({...payload});

        setOrders(data.content);

        if (firstCall) {
            setPagination({
                current: data.pageable?.pageNumber + 1,
                pageSize: data.pageable?.pageSize,
                total: data.totalElements
            });
        }
    });
    

    const handleTableChange = async (tableReceivedPagination) => {
        setPagination(tableReceivedPagination);
        fetchOrders({
            current: tableReceivedPagination.current, 
            pageSize: tableReceivedPagination.pageSize, 
            firstCall: false,
            searchTerm: searchTerm,
            amountMin: amountMin,
            amountMax: amountMax,
            createdAtFrom: createdAtFrom,
            createdAtTo: createdAtTo,
            status: orderStatus,
            serviceType: paymentMethod
        });
    }
    
    const download = (data) => {
        const blob = new Blob(["\uFEFF"+data], {
            type: 'text/csv; charset=utf-8'
        });
        
        const url = URL.createObjectURL(blob);
        
        const a = document.createElement('a');
        
        a.href = url;
        const titleWithFilters = `Заказы.${searchTerm ? ` Запрос: ${searchTerm}.` : ''}${amountMin ? ` Минимальная сумма: ${amountMin}.` : ''}${amountMax ? ` Максимальная сумма: ${amountMax}.` : ''}${createdAtFrom ? ` Дата создания от: ${createdAtFrom}.` : ''}${createdAtTo ? ` Дата создания по: ${createdAtTo}.` : ''}${orderStatus ? ` Статус: ${orderStatus} ` : ''}${paymentMethod ? ` Метод оплаты: ${paymentMethod}.` : ''}`;
        a.download = `${titleWithFilters}.csv`;
        
        a.click();
    }

    const [exportCsv, isLoadingExportCsv] = useFetching(async () => {
        const {data} = await OrderService.exportCsv({
            searchTerm: searchTerm,
            amountMin: amountMin,
            amountMax: amountMax,
            createdAtFrom: createdAtFrom,
            createdAtTo: createdAtTo,
            status: orderStatus,
            serviceType: paymentMethod
        });
        console.log(data);
        download(data);
    });

    useEffect(() => {
        fetchOrders({
            current: pagination.current,
            pageSize: pagination.pageSize,
            firstCall: true
        });
    }, []);


    return (
        <div>
            <Divider orientation="left">Поиск по заказам</Divider>
                    <Input
                        size="large"
                        placeholder="Номер заказа, WTP Skipass, ФИО, телефон, email"
                        style={{ width: 400, marginRight: 20 }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Input 
                        size="large"
                        placeholder="Минимальная сумма заказа"
                        style={{ width: 250, marginRight: 20 }}
                        value={amountMin}
                        type="number"
                        onChange={(e) => setAmountMin(e.target.value)}
                    />
                    <Input 
                        size="large"
                        placeholder="Максимальная сумма заказа"
                        style={{ width: 250 }}
                        value={amountMax}
                        type="number"
                        onChange={(e) => setAmountMax(e.target.value)}
                    />
                    <Form.Item
                        label="Начало даты создания заказа:" style={{ marginBottom: 20, marginTop: 20}}>
                        <DatePicker onChange={onDateFromChange} placeholder="От" style={{ width: 200, marginRight: 20 }} locale={locale} />
                    </Form.Item>
                    <Form.Item
                        label="Конец даты создания заказа:" style={{ marginBottom: 20, marginTop: 20}}>
                        <DatePicker onChange={onDateToChange} placeholder="До" style={{ width: 200 }} locale={locale}/>
                    </Form.Item>
                    <Form.Item label="Выберите статус заказа" style={{ marginBottom: 20, marginTop: 20}}>
                        <Select
                            placeholder="Создан, Оплачен, Неудавшийся, Отмененный"
                            value={orderStatus}
                            onChange={(val) => setOrderStatus(val)}
                            style={{width: 200}}
                        >
                            <Option value="CREATED">Создан</Option>
                            <Option value="PAID">Оплачен</Option>
                            <Option value="FAILED">Неудавшийся</Option>
                            <Option value="CANCELLED">Отмененный</Option>
                        </Select>
                        <Button onClick={() => setOrderStatus(null)} style={{marginLeft: 20}}>
                            Сбросить
                        </Button>
                    </Form.Item>
                    <Form.Item label="Выберите метод оплаты">
                        <Select
                            style={{ width: 200 }}
                            placeholder="Kaspi, TipTop, etc"
                            value={paymentMethod}
                            onChange={(val) => setPaymentMethod(val)}
                        >   
                            <Option value="EPAYMENT">Epayment</Option>
                            <Option value="TIP_TOP">TipTop</Option>
                            <Option value="KASPI">Kaspi</Option>
                        </Select>
                        <Button onClick={() => setPaymentMethod(null)} style={{marginLeft: 20}}>
                            Сбросить
                        </Button>
                    </Form.Item>
                    <Button onClick={onSearch} type="primary" style={{marginRight: 20}}>Поиск</Button>
                    <Button onClick={resetFilters}>Сбросить фильтры</Button>
                    <Button onClick={exportCsv} style={{marginLeft: 20}}>Экспортировать CSV-файл</Button>
            <Divider/>
            <OrderList
                orders={orders}
                isLoading={isLoading}
                pagination={pagination}
                handleTableChange={handleTableChange}
            />
        </div>
    );
};

export default Orders;